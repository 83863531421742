import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography, Modal, ModalDialog, ModalClose, Skeleton, Divider, CircularProgress, Input, Card, CardContent } from "@mui/joy";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { axiosInstance } from "../../helpers/AxiosConfiguration";
import AutoBuildFormSection from "../../components/AutomaticFormBuilder";
import { convertJSONKeyToTitle } from "../../helpers/KeyConverters";
import { authGuard } from "../../helpers/AuthBarrier";
import { AlertContext } from "../../components/AlertContext";
import TimerComponent from "../../components/Timer";
import NavBlocker from "../../components/NavBlocker";

import MicIcon from '@mui/icons-material/Mic';
import { PersonOutlined } from "@mui/icons-material";
import "../../styling/CurrentVisit.css";

import { createVisit, updateVisit, uploadAudio } from "../../helpers/VisitRouter";
import { LocalVisit } from "../../helpers/VisitLocalStorage";
import RenderDifferentIcons from "../../components/AccordionIcons";
import FeedIcon from '@mui/icons-material/Feed';
import AudioIcon from "../../components/audio-display/AudioIcon";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AudioNotSubmittedModal from "../../components/modals/AudioNotSubmittedModal";
import FormNotSaveableModal from "../../components/modals/FormNotSaveableModal";
import UploadAdditionalDocsModal from "../../components/modals/UploadFilesModal";
import { useNavigate } from "react-router-dom";
import { getClientUser } from "../../helpers/UserAccounts";

const visitFormFields = {
    "field_order": ["date", "efrt_activation_time", "ambulance_number", "transport"],
    "date": {
        "type": "datetime",
        "display": "datetime_date",
        "required": true,
    },
    "efrt_activation_time": {
        "type": "datetime",
        "display": "datetime_hour",
        "required": true,
    },
    "ambulance_number": {
        "type": "string",
        "required": true,
    },
    "transport": {
        "type": "string",
        "display": "dropdown",
        "values": ["Code 1 (Non-Transport)", "Code 1 (Immediate OMA)", "Code 1 (Security)", "Code 2 (McMaster Children's Hospital)", "Code 2 (Security to McMaster Children's Hospital)", "Code 3 (Ambulance)", "Other"],
        "required": true,
    }
}

const patientFormFields = {
    "field_order": ["first_name", "last_name", "sex", "date_of_birth", "address", "id_number"],
    "first_name": {
        "type": "string",
        "required": true,
    },
    "last_name": {
        "type": "string",
        "required": true,
    },
    "sex": {
        "type": "toggle",
        "values": ["male", "female", "other"]
    },
    "date_of_birth": {
        "type": "datetime",
        "display": "datetime_date",
        "required": true,
    },
    "address": {
        "type": "string",
        "required": true,
    },
    "id_number": {
        "type": "string",
        "required": true,
    },
};

const useDebounce = (callback, delay) => {
    const debounceRef = React.useRef(null);
  
    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
};

const currentVisit = new LocalVisit();

function NewVisit() {
    const { addAlert } = useContext(AlertContext);
    const navigate = useNavigate();
    const [ organizationFormFields, setOrganizationFormFields ] = useState(null);
    const [ allInitialInputs, setAllInitialInputs ] = useState(null);

    const [ currentView, setCurrentView ] = useState('input');
    const [ stateAudio, setStateAudio ] = useState(null);

    const [ dirtyForm, setDirtyForm ] = useState(false);
    const [ awaitingResult, setAwaitingResult ] = useState(false);

    const [ isCapturing, setIsCapturing ] = useState(false);
    const [ formState, setFormState ] = useState("patient");
    const [ openConfirmModal, setOpenConfirmModal ] = useState(false);
    const [ openLocalSaveModalConfirm, setLocalSaveModalConfirm ] = useState(false);
    const [ openAttachmentsModal, setOpenAttachmentsModal ] = useState(false);
    const [ openConfirmStartNewForm, setConfirmStartNewForm ] = useState(false);
    const [ completeClicked, setCompleteClicked ] = useState(false);
    const [ autoSaved, setAutoSaved ] = useState(true);

    // For recording and audio encrypt
    var mediaRecorder = useRef(null);
    var audioCodecs = useRef(null);
    var audioChunks = useRef([]);

    // Other recording support
    var wakeLock = useRef(null);

    const handleCompleteClick = () => {
        setOpenConfirmModal(true);
    };

    const handleConfirmComplete = async () => {
        setOpenConfirmModal(false);
        setCompleteClicked(true);
        await triggerDictation();
    };


    const fetchOrgFields = async () => {
        await axiosInstance.get('/api/v1/organization/details').then(response => {
            setOrganizationFormFields(response.data);
        }).catch( error => {
            console.log(error);
        })
    }

    const setOrganizationFormFieldsInput = (key, input) => {
        currentVisit.setValues(key, input)
    }

    const setPatientFieldsInput = (input) => {
        currentVisit.setPatient(input)
    }

    const setTitleInput = (input) => {
        currentVisit.setTitle(input)
    }

    const setFormTopInput = (input) => {
        currentVisit.setFormTop(input);
    }
    
    const toggleView = (input) => {
        setCurrentView(input)
    }

    const getBrowserMicrophone = async () => {
        let audio;

        try {
            audio = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false,
            });
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                addAlert('Please give OneChart permission to access your microphone.', 'danger')
            }
            return;
        }

        try {
            if ('mimeTypes' in navigator) {
                if (MediaRecorder.isTypeSupported('audio/webm; codecs=opus')) {
                    mediaRecorder.current = new MediaRecorder(audio, { mimeType: 'audio/webm; codecs=opus' });
                    audioCodecs.current = 'audio/webm; codecs=opus'
                } else if (MediaRecorder.isTypeSupported('audio/mp4; codecs=mp4a.40.2')) {
                    mediaRecorder.current = new MediaRecorder(audio, { mimeType: 'audio/mp4; codecs=mp4a.40.2' });
                    audioCodecs.current = 'audio/mp4; codecs=mp4a.40.2'
                } else if (MediaRecorder.isTypeSupported('audio/webm')) {
                    mediaRecorder.current = new MediaRecorder(audio, { mimeType: 'audio/webm' });
                    audioCodecs.current = 'audio/webm'
                } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                    mediaRecorder.current = new MediaRecorder(audio, { mimeType: 'audio/mp4' });
                    audioCodecs.current = 'audio/mp4'
                } else {
                    mediaRecorder.current = new MediaRecorder(audio);
                }
            } else {
                mediaRecorder.current = new MediaRecorder(audio);
            }

        } catch (error) {
            addAlert('Something went wrong retrieving the microphone.', 'danger');
            return;
        }

        try {
            wakeLock.current = await navigator.wakeLock.request("screen");
            console.log("Wake lock requested.");
        } catch (error) {
            console.log("Something went wrong with the wake lock.")
        }

        // Setup media flow
        try {
            mediaRecorder.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            }

            mediaRecorder.current.start();
            setStateAudio(audio);
        } catch (error) {
            addAlert("Something went wrong setting up the audio", 'danger');
            return;
        }
        return;
    }

    const stopMicrophone = async () => {
        try {
            if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
                mediaRecorder.current.stop();
                // Wait for the last chunk of audio to be captured
                await new Promise(resolve => {
                    mediaRecorder.current.addEventListener("stop", resolve, { once: true });
                });
            }
            
            if (stateAudio) {
                stateAudio.getTracks().forEach(track => track.stop());
            }
            
            if (mediaRecorder.current && mediaRecorder.current.stream) {
                mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
            }

            if (wakeLock.current) {
                wakeLock.current.release().then(() => {
                    wakeLock.current = null;
                    console.log("Wake lock released");
                })
            }

            mediaRecorder.current = null;
            setStateAudio(null);
        } catch (error) {
            console.log(error);
            addAlert("There was a problem trying to stop your microphone.", "danger")
        }
    }

    const toggleMicrophone = async () => {
        if (stateAudio) {
            await stopMicrophone();
            setIsCapturing(false);
        } else {
            // Verify token
            authGuard();
            await getBrowserMicrophone();
            setIsCapturing(true);
        }
    }

    const triggerDictation = async () => {
        try {
            // Check that patient has been created
            if (currentVisit.getID() === null || currentVisit.getID() === "null") {
                await triggerAutosave();
                if (currentVisit.getID() === null || currentVisit.getID() === "null") {
                    addAlert("Please fill out the patient information first.", "danger");
                    setAwaitingResult(false);
                    setCompleteClicked(false);
                    return;
                }
            }
    
            const uploadResult = await uploadAudio(audioChunks.current, audioCodecs.current, currentVisit.getID());
            if (uploadResult.success) {
                setAwaitingResult(true);
                setIsCapturing(false);
                audioChunks.current = [];
            } else {
                addAlert(uploadResult.message, "danger");
                setAwaitingResult(false);
                setCompleteClicked(false);
                return;
            }
    
            const maxWaitTime = 400000; // 400 seconds
            const intervalTime = 4000; // 4 seconds
    
            const pollForResults = () => new Promise((resolve, reject) => {
                const startTime = Date.now();
                const intervalID = setInterval(async () => {
                    try {
                        const response = await axiosInstance.get(`/api/v1/visits/${currentVisit.getID()}`);
    
                        let allComeTrue = true;
                        for (let section in organizationFormFields["form_fields"]) {
                            if (!response.data) {
                                allComeTrue = false;
                                break;
                            }
                            if (!(section in response.data)) {
                                allComeTrue = false;
                                continue;
                            } else if ("value" in response.data[section].generated_result && response.data[section].generated_result) {
                                currentVisit.addGenResult(section, response.data[section].generated_result.value);
                            } else {
                                allComeTrue = false;
                            }
                        }
    
                        if (allComeTrue) {
                            setOrganizationFormFields(null); // Reset organization form fields
                            clearInterval(intervalID);
                            resolve();
                        }
    
                        if (Date.now() - startTime > maxWaitTime) {
                            clearInterval(intervalID);
                            toggleView('input');
                            reject(new Error("Timeout: Dictation results not received within 40 seconds"));
                        }
                    } catch (error) {
                        clearInterval(intervalID);
                        reject(error);
                    }
                }, intervalTime);
            });
    
            await pollForResults();
    
            addAlert("Call, Treatment and Vitals forms autofilled", "success");
            setOrganizationFormFields(null);
            await loadFromLocalStorage();
            await fetchOrgFields();
            toggleView('input');
        } catch (error) {
            console.log(error);
            addAlert("Something unexpected happened. Please start a new form", "danger");
        } finally {
            setAwaitingResult(false);
        }
    };

    const checkVisitCreated = () => {
        const currentPatient = currentVisit.getPatient();
        const currentDetails = currentVisit.getFormTop();

        if (currentVisit.getID() !== null && currentVisit.getID() !== "null") {
            return true;
        }

        for (let key in patientFormFields) {
            if (key === "field_order") {
                continue;
            }
            
            if (currentPatient[key] === undefined || currentPatient[key].length <= 0) {
                return false;
            }
        }

        for (let key in visitFormFields) {
            if (key === "field_order" || key === "efrt_activation_time" || key === "ambulance_number") {
                continue;
            }

            if (currentDetails[key] === undefined || currentDetails[key].length <= 0) {
                return false;
            }
        }
        return true;
    }

    const checkLocalChanges = () => {
        const currentPatient = currentVisit.getPatient();
        const currentDetails = currentVisit.getFormTop();

        if (currentVisit.getID() !== null && currentVisit.getID() !== "null") {
            return false;
        }

        for (let key in patientFormFields) {
            if (key === "field_order") {
                continue;
            }
            
            if (currentPatient[key] !== undefined) {
                return true;
            }
        }


        for (let key in visitFormFields) {
            if (key === "field_order" || key === "efrt_activation_time" || key === "ambulance_number") {
                continue;
            }

            if (currentDetails[key] !== undefined) {
                return true;
            }
        }
        return false;
    }

    const triggerAutosave = useCallback(async () => {
        try {
            if (currentVisit.getID() !== null && currentVisit.getID() !== "null") {
                await updateVisit(currentVisit.getID(), currentVisit.getValues(), currentVisit.getPatient(), currentVisit.getFormTop(), currentVisit.getFormTitle()).then(() => {
                    setAutoSaved(true)
                }).catch((error) => {
                    if (autoSaved) {
                        addAlert("Report was not saved. Reload or restart the report.", "warning")
                    }
                    setAutoSaved(false)
                });
                setDirtyForm(false);
                return ;
            }

            if (checkVisitCreated() && (currentVisit.getID() === null || currentVisit.getID() === "null")) {
                const newID = await createVisit(currentVisit.getPatient(), currentVisit.getFormTop());
                currentVisit.setID(newID);
                setAutoSaved(true);
                addAlert("Report created and saved!", "success");
            }
        } catch (error) {
            addAlert("Something went wrong with saving the form", "danger");
        } finally {
            setDirtyForm(false);
        }
      }, [addAlert, dirtyForm, currentVisit, autoSaved, updateVisit, createVisit]);
    
    const debouncingAutosave = useDebounce(triggerAutosave, 700);

    const loadFromLocalStorage = async () => {
        const inputs = await currentVisit.initializeFromLocalStorage();
        setAllInitialInputs(inputs);
        if (currentVisit.getID() !== "null") {
            const genValues = currentVisit.getValues()
            for (const key in genValues) {
                if ((genValues[key] !== "null") && ('generated_result' in genValues[key]) && (Object.keys(genValues[key]['generated_result'].length > 0))) {
                    setCompleteClicked(true);
                }
            }
        }
    }

    const resetForm = useCallback(() => {
        currentVisit.clearLocalStorage();
        setAllInitialInputs({
            id: null,
            form_top: {},
            patient: {},
            values: {}
        });
        setOrganizationFormFields(null); // Reset organization form fields
        fetchOrgFields(); // Fetch organization fields again
        setAwaitingResult(false);
        setCompleteClicked(false);
        setFormState("patient");
    }, []);

    const checkIsOrgAdmin = async () => {
        try {
            const user = await getClientUser();
            if (user.type === "organization_admin") {
                addAlert("Please note that reports should be created by responders!", "warning");
                navigate("/past-reports");
            }
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        checkIsOrgAdmin();
        loadFromLocalStorage();
        fetchOrgFields();
    }, [])

    // Check dictation state
    useEffect(() => {
        if (currentVisit.getID() !== "null") {
            const genValues = currentVisit.getValues()
            for (const key in genValues) {
                if ((genValues[key] !== "null") && ('generated_result' in genValues[key])) {
                    setCompleteClicked(true);
                }
            }
        }
    }, [])
    
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (dirtyForm) {
                triggerAutosave();
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (dirtyForm || !autoSaved) {
            console.log("Trigger save");
            debouncingAutosave();
        }
    }, [dirtyForm, autoSaved, debouncingAutosave])

    return (<Box sx={{height: '100vh'}}>
        {organizationFormFields ? (
        <Box>
            { formState === "form" && <UploadAdditionalDocsModal open={openAttachmentsModal} setClose={() => { setOpenAttachmentsModal(false); }} visitID={currentVisit.getID()} visit_status={"draft"}/> }
            <NavBlocker dirty={dirtyForm || audioChunks.current.length > 0 || isCapturing}/>
            <FormNotSaveableModal open={openLocalSaveModalConfirm} onClose={() => setLocalSaveModalConfirm(false)} confirm={() => {
                setLocalSaveModalConfirm(false);
                resetForm()
                }}/>
            <AudioNotSubmittedModal open={openConfirmStartNewForm} onClose={() => {
                setConfirmStartNewForm(false);
            }} submitAudio={() => {
                if (stateAudio) {
                    toggleMicrophone();
                }
                handleCompleteClick();
            }} continueSaveForm={() => {
                triggerAutosave();
                resetForm();
            }}/>
            <img src={organizationFormFields["settings"]["logo_url"]} alt="Organization Logo" style={{height: "10vh", minHeight: "85px", padding: "15px"}}/>

            <Divider sx={{width: "100%"}}/>
            <Box sx={{ display: formState === "patient" ? "block" : "none", px: 4 }}>
                <Typography sx={{py: 1}}> 
                    <Typography level="h3">
                        Instructions: &nbsp;
                    </Typography>
                    Please fill out the patient's information for the report first to enable autosaving to the cloud and for AI dictation.
                </Typography>
                {allInitialInputs && 
                <Box>
                    {/* Date, EMS Activation, Ambulance #, Transport*/}
                    <Box sx={{p: 2, border: "1px #c5cfd3 solid", borderRadius: "10px", overflow: 'auto'}} key={'visit_form'} id='visit_details'>
                        <Typography level='h3' startDecorator={<FeedIcon fontSize="xl4"/>}> Report Information</Typography>
                        <Divider sx={{my: 1}}/>
                        <AutoBuildFormSection formFields={visitFormFields} retrieveInput={setFormTopInput} initialInput={allInitialInputs["form_top"]} setDirty={setDirtyForm} dirty={dirtyForm}/>
                    </Box>
                    <Divider sx={{my: 3}}/>
                    <Box sx={{p: 2, border: "1px #c5cfd3 solid", borderRadius: "10px", overflow: 'auto'}} key={'patient_form'} id='patient_form'>
                        <Typography level='h3' startDecorator={<PersonOutlined fontSize="xl4"/>}> Patient Information</Typography>
                        <Divider sx={{my: 1}}/>
                        <AutoBuildFormSection formFields={patientFormFields} retrieveInput={setPatientFieldsInput} initialInput={allInitialInputs['patient']} setDirty={setDirtyForm} dirty={dirtyForm}/>
                    </Box>
                </Box>
                }
                <Box sx={{display: "flex", justifyContent: "end", gap: 1, marginTop: 1.5}}>
                    <Button variant="outlined" sx={{backgroundColor: "white"}} onClick={async () => {
                        if (audioChunks.current.length > 0 || isCapturing) {
                            setConfirmStartNewForm(true);
                        } else if (checkLocalChanges()){
                            setLocalSaveModalConfirm(true);
                        } else {
                            resetForm()
                        }
                    }}>
                        Start new form
                    </Button>
                    <Button onClick={async () => {
                        if (checkVisitCreated()) {
                            await triggerAutosave().then(() => addAlert('Saved!', 'success'))
                        } else {
                            addAlert('Saved locally, not enough information to save to the cloud', 'warning');
                        }
                    }}>
                        Save
                    </Button>
                    <Button 
                        sx={{
                            color: "white",
                            backgroundColor: "var(--dark-blue-button)",
                            '&:hover': {
                                backgroundColor: "var(--dark-blue-button-hover)",
                            },
                        }}
                    onClick={async () => {
                        if (checkVisitCreated()) {
                            if (currentVisit.getID() === null || currentVisit.getID() === "null") {
                                await triggerAutosave()
                            }
                            setFormState("form")
                        } else {
                            addAlert("Please fill out the report and patient information first!", "danger");
                        }
                        }}>
                        Next
                    </Button>
                </Box>
            </Box>



            {/* ALL SECTIONS OF THE FORM */}
            <Box sx={{ display: formState === "form" ? "block" : "none", px: 4 }}>
                <Typography sx={{py: 1}}> 
                    <Typography level="h4">
                        Instructions: &nbsp; &nbsp; &nbsp;
                    </Typography>
                    Use smart dictate to help autofill the Patient Care Report. You can then review the information captured manually.
                </Typography>
                
                {/* THIS IS THE MANUAL INPUT FORM*/}
                <Box sx={{ display: (currentView === "input" ? "block" : "none"), height: "65vh", overflowY: "scroll", marginTop: '2vh', marginBottom: '1vh', gap: 3, position: "relative"}}>
                    {/* Dictation, disable if dictation is on */}
                    <Box sx={{display: "flex", justifyContent: "space-between", gap: 2, marginBottom: '2vh', alignItems: "center", width: "100%", position: ((completeClicked) ? "relative" : "sticky"), top: 0, zIndex: 10, backgroundColor: "white" }}> 
                        <Card color={autoSaved ? "success" : "danger"} variant="soft">
                            <CardContent sx={{display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}>
                                {autoSaved ? <CheckCircleOutlineIcon color='success'/> : <ErrorOutlineIcon color='error'/>}
                                {autoSaved ? <Typography color='success'> Autosaved! You can view this report in your past reports. </Typography> : <Typography color='danger'> Unable to autosave. Will retry...</Typography>}
                            </CardContent>
                        </Card>
                        <TimerComponent start={isCapturing}/>
                        {stateAudio && <Box className="recording-dot"/>}
                        {awaitingResult && <CircularProgress variant="soft" size="sm"/>}
                        <Button variant="plain" sx={{display: "flex", border: "1px var(--main-blue) solid", color: "var(--main-blue)", backgroundColor: "white", width: "45%"}} startDecorator={<MicIcon />} endDecorator={stateAudio && <AudioIcon audio={stateAudio}/>} onClick={() => toggleMicrophone()} disabled={completeClicked}> {isCapturing ? "Pause" : "Dictate"} </Button>
                        <Button onClick={handleCompleteClick} sx={{backgroundColor: "var(--main-blue)", border: "1px var(--main-blue) solid", width: "15%", color: "white"}} disabled={(stateAudio || awaitingResult || audioChunks.current.length === 0 || completeClicked)}> Complete</Button>
                    </Box>
                    {allInitialInputs && 
                        <Box>
                            <Box sx={{display: "flex", gap: 4}}>
                                <Input value={allInitialInputs["title"]} onChange={(event) => {
                                    setTitleInput(event.target.value);
                                    setAllInitialInputs((currentValue) => ({
                                        ...currentValue,
                                        "title": event.target.value
                                    }))
                                    !dirtyForm && setDirtyForm(true);
                                }} sx={{ width: "35%"}} startDecorator={<Typography> Title: </Typography>}/>
                                <Button
                                    startDecorator={<FileUploadIcon />}
                                    sx={{
                                        display: "flex",
                                        color: "white",
                                        flex: 1,
                                        backgroundColor: "var(--dark-blue-button)",
                                        '&:hover': {
                                            backgroundColor: "var(--dark-blue-button-hover)",
                                        },
                                    }}
                                    onClick={() => {
                                        setOpenAttachmentsModal(true);
                                    }}
                                > Attachments </Button>
                            </Box>
                            <Divider sx={{my: 2}}/>
                            {Object.keys(organizationFormFields['form_fields']).map( key => {
                                let initial_field;
                                if (allInitialInputs['values']) {
                                    initial_field = (key in allInitialInputs['values']) ? (allInitialInputs['values'][key]) : (null)
                                } else {
                                    initial_field = null;
                                }
                                return <Box>
                                    <Accordion square={false} variant="outlined" sx={{borderBottom: "1px #c5cfd3 solid", borderRadius: "10px"}} key={key} id={key}>
                                        <AccordionSummary sx={{
                                            '.MuiAccordionSummary-button': {
                                                borderRadius: "10px"
                                            }}} >
                                            {/* Need logic to render an icon based off of the title of the section*/}
                                            <Box sx={{display: "flex", pl: 1.5, gap: 1, alignItems: "center"}}>
                                                <RenderDifferentIcons label={key}/>
                                                <h3>{convertJSONKeyToTitle(key)}</h3>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{overFlowX: "scroll",
                                            '.MuiAccordionDetails-content': {
                                                overflowX: 'auto'
                                            }
                                        }}>
                                            <Divider sx={{mb: 1}}/>
                                            <AutoBuildFormSection formFields={organizationFormFields['form_fields'][key]} initialInput={initial_field} retrieveInput={(input) => {setOrganizationFormFieldsInput(key, input)}} setDirty={setDirtyForm} dirty={dirtyForm} disabled={awaitingResult}/>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Divider sx={{my: 2}}/>
                                </Box>
                            })}
                        </Box>
                    }
                </Box>
                <Box sx={{display: "flex", justifyContent: "end", gap: 1}}>
                    <Button variant="outlined" sx={{backgroundColor: "white"}} onClick={() => {setFormState("patient")}}>
                        Back
                    </Button>
                    <Button onClick={async () => {
                        await triggerAutosave();
                        if (currentVisit.getID() === "null" || currentVisit.getID() === null) {
                            addAlert("Report saved locally!", "success")
                        }
                        }}>
                        Save
                    </Button>
                    <Button 
                    sx={{
                        color: "white",
                        backgroundColor: "var(--dark-blue-button)",
                        '&:hover': {
                            backgroundColor: "var(--dark-blue-button-hover)",
                        },
                    }}
                    onClick={ async () => {
                        if (audioChunks.current.length > 0 || isCapturing) {
                            setConfirmStartNewForm(true);
                        } else {
                            await triggerAutosave();
                            resetForm();
                        }
                        }}>Start new form</Button>
                </Box>
            </Box>
            <Modal
                open={openConfirmModal}
                onClose={() => setOpenConfirmModal(false)}
            >
                <ModalDialog
                    variant="outlined"
                    role="alertdialog"
                    sx={{
                        maxWidth: 400,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'sm',
                    }}
                >
                    <ModalClose />
                    <Typography
                        level="h5"
                        fontWeight="lg"
                        mb={2}
                    >
                        Confirm Completion
                    </Typography>
                    <Typography mb={3}>
                        Are you sure you want to complete the dictation?
                    </Typography>
                    <Typography level="body2" mb={3} sx={{ color: 'text.secondary' }}>
                        You won't be able to click 'DICTATE' again unless you start a new form.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
                        <Button variant="plain" sx={{color: "black", border: "1px black solid", backgroundColor: "white"}} onClick={() => setOpenConfirmModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="primary" onClick={handleConfirmComplete}>
                            Confirm
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </Box>) : (<Skeleton variant="overlay">
            Loading
        </Skeleton>)}
    </Box>)
}

export default NewVisit;